<template>
  <div>
    {{ proprio }}
  </div>
</template>



<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    methods: {
        
    },
    computed: {
      ...mapGetters({
        proprios: 'auth/proprietaires'
      }),
      proprio(){
        let p = this.proprios.find(item => item.uid === this.object.proprio)
        if(p) return p.fullname
        return '-'
      }
    }

}

</script>
<style lang="scss">
.actions{
    a{
        
    }
}
</style>